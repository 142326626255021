<template>
  <svg
    class="svg-check-icon2"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 5.25L7.875 14.875L3.5 10.5"
      stroke="#2E4EA4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon2',
};
</script>

<style scoped>
.svg-check-icon2 {
  width: 21px;
  height: 21px;
}
</style>
