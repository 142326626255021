<template>
  <div class="form-user">
    <div class="form-user-info">
      <div class="form-user-info__item">
        <div class="form-user-info__value">{{ volunteersCount }}</div>
        <div class="form-user-info__text">активистов</div>
      </div>
      <div class="form-user-info__item">
        <div class="form-user-info__value">{{ total }}</div>
        <div class="form-user-info__text">не участвовали</div>
      </div>
    </div>
    <p class="form-user__title">{{ title }}</p>
    <input
      class="form-user__input input"
      placeholder="Поиск"
      type="text"
      v-model="searchString"
    />
    <div class="form-user-list">
      <div class="form-user-list__wrap">
        <label
          class="form-user-list__item"
          v-for="volunteer in volunteers"
          :key="volunteer.phone"
        >
          {{ volunteer.surname }} {{ volunteer.name }}
          {{ volunteer.patronymic }}
          <input
            type="radio"
            name="volunteerPhone"
            :value="volunteer.phone"
            v-model="selectedVolunteerPhone"
          />
          <CheckIcon />
        </label>
      </div>

      <PaginationBlock
        :pageCount="pageCount"
        :currentPage="currentPage"
        @pageChanged="updateCurrentPage"
      />
    </div>
    <div class="form-user-radio">
      <label class="form-user-radio__label radio-label">
        <input
          class="form-user-radio__input radio-label__input"
          type="radio"
          name="type"
          value="itself"
          v-model="unitType"
        />
        <span class="radio-label__text">Визуальный контакт</span>
      </label>
      <label class="form-user-radio__label radio-label">
        <input
          class="form-user-radio__input radio-label__input"
          type="radio"
          name="type"
          value="callcenter"
          v-model="unitType"
        />
        <span class="radio-label__text">Call-центр</span>
      </label>
    </div>

    <button
      @click="removeVolunteer"
      :disabled="!selectedVolunteerPhone"
      class="form-user__button button"
      type="button"
    >
    Отметить как участника
    </button>
  </div>

  <MessageBlock :type="resultType" v-if="resultMessage">
    <CheckIcon2 v-if="resultType === ''" />
    <CloseIcon v-if="resultType !== ''" />

    {{ resultMessage }}
  </MessageBlock>
</template>

<script>
import MessageBlock from '@/components/message/MessageBlock.vue';
import PaginationBlock from '@/components/pagination/PaginationBlock.vue';
import CheckIcon from '@/components/svg/CheckIcon.vue';
import CheckIcon2 from '@/components/svg/CheckIcon2.vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';
import axios from 'axios';
const API_GET_USERS = process.env.VUE_APP_API_GET_USERS;
const API_DEL_USER = process.env.VUE_APP_API_DEL_USER;
const API_PORT = process.env.VUE_APP_API_PORT;
const API_DOMAIN =
  process.env.NODE_ENV == 'development' ? `http://127.0.0.1:${API_PORT}` : '';

export default {
  name: 'FormUser',
  components: {
    CloseIcon,
    CheckIcon,
    CheckIcon2,
    MessageBlock,
    PaginationBlock,
  },
  data() {
    return {
      title: 'Выберите активиста',
      volunteers: [],
      searchString: '',
      pageSize: 10,
      currentPage: 1,
      total: 0,
      pageCount: 0,
      selectedVolunteerPhone: '',
      unitType: 'itself',
      resultMessage: '',
      resultType: '',
      timerId: 0,
      volunteersCount: 0
    };
  },
  mounted() {
    this.getVolunteers();
  },
  methods: {
    getVolunteers() {
      let searchStringParameter = this.searchString.replace(' ', '+');
      const token = this.getToken();
      axios
        .get(
          `${API_DOMAIN + API_GET_USERS}?unit=edg-&page=${this.currentPage}&pageSize=${this.pageSize}${searchStringParameter && searchStringParameter.length > 0 ? `&fullname=${searchStringParameter}` : ``}`,
          {
            headers: {
              Authorization: 'Token ' + token,
            },
          },
        )
        .then((response) => {
          if (response.data.ok) {
            this.total = response.data.total;
            this.volunteersCount = response.data.totals[0].total;
            this.volunteers = response.data.volunteers;
            this.pageCount = Math.ceil(this.total / this.pageSize);
            this.selectedVolunteerPhone = '';
          } else {
            this.resultMessage = 'Ошибка сервера';
            this.resultType = 'error';
          }
        })
        .catch(() => {
          this.resultMessage = 'Ошибка сервера';
          this.resultType = 'error';
        });
    },
    updateCurrentPage(value) {
      this.currentPage = value;
      this.getVolunteers();
    },
    removeVolunteer() {
      const token = this.getToken();
      axios
        .patch(
          `${API_DOMAIN + API_DEL_USER}/edg+${this.unitType}/phones`,
          { add: [this.selectedVolunteerPhone] },
          {
            headers: {
              Authorization: 'Token ' + token,
            },
          },
        )
        .then((response) => {
          if (response.data.ok) {
            const that = this;
            this.resultMessage = 'Активист удален';
            this.resultType = '';
            this.getVolunteers();

            clearTimeout(this.timerId);
            this.timerId = setTimeout(function () {
              that.resultMessage = '';
            }, 10000);
          } else {
            clearTimeout(this.timerId);
            this.resultMessage = 'Ошибка сервера';
            this.resultType = 'error';
          }
        })
        .catch(() => {
          clearTimeout(this.timerId);
          this.resultMessage = 'Ошибка сервера';
          this.resultType = 'error';
        });
    },
    getToken() {
      const expires = window.localStorage.getItem('expires') || '';
      if (expires && new Date() < new Date(Number(expires) * 1000)) {
        return window.localStorage.getItem('token') || '';
      } else {
        window.localStorage.removeItem('expires');
        window.localStorage.removeItem('token');
        this.$router.push('/');
      }
    },
  },
  watch: {
    searchString() {
      this.getVolunteers();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-user {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 390px;
  width: 100%;
  margin-top: 96px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  
  @media (max-width: 767px) {
    flex-grow: 1;
    max-width: none;
    width: auto;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
  }

  &__title {
    margin-bottom: 40px;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    color: #151515;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 22px;
      line-height: 28px;
    }
  }

  &__input {
    margin-bottom: 20px;
  }

  &-info {
    display: flex;
    max-width: 335px;
    width: 100%;
    margin-bottom: 50px;
    padding: 20px 30px;
    border-radius: 20px;
    background-color: #ffffff;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
    }

    &__value {
      margin-bottom: 10px;
      font-family: 'Onest', sans-serif;
      font-size: 30px;
      font-weight: 400;
      line-height: 24px;
    }

    &__text {
      font-family: 'Onest', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;

    }
  }

  &-list {
    width: 100%;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 9px;
      padding: 10px 12px 10px 15px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #151515;
      border-radius: 10px;
      background-color: #ffffff;
      cursor: pointer;

      input {
        display: none;

        &:checked + svg {
          opacity: 1;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 24px;
      }

      svg {
        margin-top: -2px;
        opacity: 0;
        transition: 0.35s opacity;
      }
    }
  }

  &-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 33px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    &__label {
      @media (max-width: 767px) {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
