<template>
  <div class="form-phone">
    <p class="form-phone__header">{{ msg }}</p>
    <p class="form-phone__title">{{ title }}</p>
    <input
      class="form-phone__input input"
      placeholder="+7 (___)  ___-__-__"
      type="text"
      data-maska-eager
      v-maska:phone.unmasked="'+7 (###) ###-##-##'"
      v-model="maskedPhone"
    />
    <button
      class="form-phone__button button"
      @click="sendPhone"
      :class="{ disabled: phone.length !== 10 }"
      type="button"
    >
      Далее
    </button>
  </div>

  <MessageBlock type="error" v-if="resultMessage">
    <CloseIcon />
    {{ resultMessage }}
  </MessageBlock>
</template>

<script>
import axios from 'axios';
import MessageBlock from '@/components/message/MessageBlock.vue';
import CloseIcon from '@/components/svg/CloseIcon.vue';
const API_FORM_PHONE = process.env.VUE_APP_API_FORM_PHONE;
const API_PORT = process.env.VUE_APP_API_PORT;
const API_DOMAIN =
  process.env.NODE_ENV == 'development' ? `http://localhost:${API_PORT}` : '';

export default {
  name: 'FormPhone',
  components: {
    CloseIcon,
    MessageBlock,
  },
  data() {
    return {
      msg: 'Авторизация',
      title: 'Введите номер телефона для авторизации',
      phone: '',
      maskedPhone: '',
      resultMessage: '',
    };
  },
  mounted() {
    const expires = window.localStorage.getItem('expires') || '';
    if (expires && new Date() < new Date(Number(expires) * 1000)) {
      this.$router.push('/user');
    }

    this.maskedPhone = window.localStorage.getItem('phone') || '';
  },
  methods: {
    sendPhone() {
      axios
        .post(API_DOMAIN + API_FORM_PHONE, { phone: this.phone, identity: 'mobile_web' })
        .then((response) => {
          if (response.data.ok) {
            window.localStorage.setItem('phone', this.phone);
            this.$router.push('/sms-code');
          } else {
            this.resultMessage = 'Ошибка сервера';
          }
        })
        .catch(() => {
          this.resultMessage = 'Ошибка сервера';
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-phone {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 657px;
  width: 100%;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 133px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 40px;
  background-color: #ffffff;

  @media (max-width: 767px) {
    flex-grow: 1;
    max-width: none;
    width: auto;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 40px 20px 20px 20px;
    border-radius: 20px;
  }

  &__header {
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #696c73;

    @media (max-width: 767px) {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 25.2px;
    }
  }

  &__title {
    margin-bottom: 40px;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    color: #151515;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 22px;
      line-height: 28px;
    }
  }

  &__input {
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}
</style>
