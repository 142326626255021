<template>
  <div class="message" :class="type">
    <div class="message__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBlock',
  props: ['type'],
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  justify-content: center;
  margin: 40px auto 0;

  @media (max-width: 767px) {
    margin: 20px 20px 0;
  }

  &.error .message__text {
    color: #b80000;
  }
}

.message__text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  border-radius: 40px;
  color: #2e4ea4;
  background-color: #ffffff;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.message__text svg {
  margin-right: 10px;
}
</style>
