<template>
  <div class="pagination" v-if="pageCount > 1">
    <div class="pagination-list">
      <button
        class="pagination-list__item"
        @click="updateCurrentPage(1)"
        :class="{ active: currentPage === 1 }"
        type="button"
      >
        1
      </button>
      <button
        v-if="currentPage < 5"
        @click="updateCurrentPage(2)"
        :class="{ active: currentPage === 2 }"
        class="pagination-list__item"
        type="button"
      >
        2
      </button>
      <button
        v-if="currentPage < 5"
        @click="updateCurrentPage(3)"
        :class="{ active: currentPage === 3 }"
        class="pagination-list__item"
        type="button"
      >
        3
      </button>
      <button
        v-if="currentPage < 5"
        @click="updateCurrentPage(4)"
        :class="{ active: currentPage === 4 }"
        class="pagination-list__item"
        type="button"
      >
        4
      </button>
      <button
        v-if="currentPage < 5"
        @click="updateCurrentPage(5)"
        class="pagination-list__item"
        type="button"
      >
        5
      </button>
      <span v-if="currentPage >= 5" class="pagination-list__item">...</span>
      <button
        v-if="currentPage >= 5 && currentPage <= pageCount - 4"
        @click="updateCurrentPage(currentPage - 1)"
        class="pagination-list__item"
        type="button"
      >
        {{ currentPage - 1 }}
      </button>

      <button
        v-if="currentPage >= 5 && currentPage <= pageCount - 4"
        @click="updateCurrentPage(currentPage)"
        class="pagination-list__item active"
        type="button"
      >
        {{ currentPage }}
      </button>

      <button
        v-if="currentPage >= 5 && currentPage <= pageCount - 4"
        @click="updateCurrentPage(currentPage + 1)"
        class="pagination-list__item"
        type="button"
      >
        {{ currentPage + 1 }}
      </button>
      <span v-if="currentPage <= pageCount - 4" class="pagination-list__item"
        >...</span
      >
      <button
        v-if="currentPage > pageCount - 4"
        @click="updateCurrentPage(pageCount - 4)"
        class="pagination-list__item"
        type="button"
      >
        {{ pageCount - 4 }}
      </button>
      <button
        v-if="currentPage > pageCount - 4"
        @click="updateCurrentPage(pageCount - 3)"
        :class="{ active: currentPage === pageCount - 3 }"
        class="pagination-list__item"
        type="button"
      >
        {{ pageCount - 3 }}
      </button>
      <button
        v-if="currentPage > pageCount - 4"
        @click="updateCurrentPage(pageCount - 2)"
        :class="{ active: currentPage === pageCount - 2 }"
        class="pagination-list__item"
        type="button"
      >
        {{ pageCount - 2 }}
      </button>
      <button
        v-if="currentPage > pageCount - 4"
        @click="updateCurrentPage(pageCount - 1)"
        :class="{ active: currentPage === pageCount - 1 }"
        class="pagination-list__item"
        type="button"
      >
        {{ pageCount - 1 }}
      </button>
      <button
        class="pagination-list__item"
        @click="updateCurrentPage(pageCount)"
        :class="{ active: currentPage === pageCount }"
        type="button"
      >
        {{ pageCount }}
      </button>
    </div>
    
    <div class="pagination-arrows">
      <button
        class="pagination-arrows__prev"
        type="button"
        @click="updateCurrentPage(currentPage - 1)"
        :disabled="currentPage == 1"
      >
        <LeftArrowNav />
      </button>
      <button
        class="pagination-arrows__next"
        type="button"
        @click="updateCurrentPage(currentPage + 1)"
        :disabled="currentPage == pageCount"
      >
        <LeftArrowNav />
      </button>
    </div>

    <div class="pagination-input">
      Перейти на:
      <input
        class="pagination-input__current-page"
        v-model="page"
        v-on:keyup.enter="setPage"
        type="number"
      />
    </div>
  </div>
</template>

<script>
import LeftArrowNav from '@/components/svg/LeftArrowNav.vue';
export default {
  name: 'PaginationBlock',
  props: ['pageCount', 'currentPage'],
  components: {
    LeftArrowNav,
  },
  data() {
    return {
      page: null,
    };
  },
  methods: {
    updateCurrentPage(value) {
      this.$emit('pageChanged', value);
    },
    setPage() {
      if (this.page < 1) this.page = 1;
      if (this.page >= this.pageCount) this.page = this.pageCount;
      this.updateCurrentPage(this.page);
      this.page = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &-list {
    display: flex;
    align-items: center;
    margin-left: -7px;

    &__item {
      padding: 0 7px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #bdbdbd;
      background-color: transparent;
      border: none;      
      cursor: pointer;

      &.active {
        color: #151515;
      }
    }
  }

  &-arrows {
    display: flex;

    &__prev {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      padding: 0;
      border-radius: 50%;
      background-color: #2e4ea4;
      border: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    &__next {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin-left: 10px;
      padding: 0;
      border-radius: 50%;
      background-color: #2e4ea4;
      border: none;
      cursor: pointer;

      svg {
        transform: rotate(180deg);
      }

      &:disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }

  &-input {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;

    &__current-page {
      width: 50px;
      margin-left: 5px;
      padding-left: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
}
</style>
