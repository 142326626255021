<template>
  <div class="page-phone">
    <HeaderBlock />
    <FormPhone />
  </div>
</template>

<script>
import HeaderBlock from '@/components/header/HeaderBlock.vue';
import FormPhone from '@/components/forms/FormPhone.vue';

export default {
  name: 'PagePhone',
  components: {
    HeaderBlock,
    FormPhone,
  },
};
</script>
