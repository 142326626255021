<template>
  <svg
    class="svg-check-icon"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="17" height="17" rx="8.5" fill="#2E4EA4" />
    <path
      d="M12.1668 6.25L7.12516 11.2917L4.8335 9"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckIcon',
};
</script>

<style scoped>
.svg-check-icon {
  width: 17px;
  height: 18px;
}
</style>
