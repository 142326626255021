<template>
  <div class="page-user">
    <HeaderBlock />
    <FormUser />
  </div>
</template>

<script>
import HeaderBlock from '@/components/header/HeaderBlock.vue';
import FormUser from '@/components/forms/FormUser.vue';

export default {
  name: 'PageUser',
  components: {
    HeaderBlock,
    FormUser,
  },
};
</script>
