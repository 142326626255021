<template>
  <div class="page-sms-code">
    <HeaderBlock />
    <FormSmsCode />
  </div>
</template>

<script>
import HeaderBlock from '@/components/header/HeaderBlock.vue';
import FormSmsCode from '@/components/forms/FormSmsCode.vue';

export default {
  name: 'PageSmsCode',
  components: {
    HeaderBlock,
    FormSmsCode,
  },
};
</script>
