<template>
  <svg
    class="svg-left-arrow-nav"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90918 11.8182L6.091 8.00001L9.90918 4.18182"
      stroke="white"
      stroke-width="0.954545"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LeftArrowNav',
};
</script>

<style scoped>
.svg-left-arrow-nav {
  width: 16px;
  height: 16px;
}
</style>
