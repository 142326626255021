<template>
  <div class="header">
    <div class="header__wrapper">
      <div class="header-logo">
        <img src="@/assets/images/logo.png" alt="" width="129" height="33" />
      </div>
      <router-link
        class="header__log-out"
        @click="logout"
        to="/"
        v-if="hasToken"
      >
        Выйти из профиля
        <RightArrow />
      </router-link>
    </div>
  </div>
</template>

<script>
import RightArrow from '@/components/svg/RightArrow.vue';
export default {
  name: 'HeaderBlock',
  components: {
    RightArrow,
  },
  data() {
    return {
      hasToken: false,
    };
  },
  mounted() {
    const expires = window.localStorage.getItem('expires') || '';
    this.hasToken = expires && new Date() < new Date(Number(expires) * 1000);
  },
  methods: {
    logout() {
      window.localStorage.removeItem('phone');
      window.localStorage.removeItem('expires');
      window.localStorage.removeItem('token');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #2e4ea4;

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1680px;
    height: 81px;
    padding: 10px 20px;

    margin-left: auto;
    margin-right: auto;
  }

  &-logo {
    @media (max-width: 767px) {
      img {
        width: 110px;
        height: auto;
      }
    }
  }
  &__log-out {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 19.6px;
    }

    svg {
      margin-left: 10px;

      @media (max-width: 767px) {
        margin-left: 5px;
      }
    }
  }
}
</style>
