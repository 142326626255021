import { createRouter, createWebHistory } from 'vue-router';
import PagePhone from '../views/PagePhone.vue';
import PageSmsCode from '../views/PageSmsCode.vue';
import PageUser from '../views/PageUser.vue';

const routes = [
  {
    path: '/',
    name: 'PagePhone',
    component: PagePhone,
  },
  {
    path: '/sms-code',
    name: 'PageSmsCode',
    component: PageSmsCode,
  },
  {
    path: '/user',
    name: 'PageUser',
    component: PageUser,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
